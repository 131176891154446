import React from 'react'

const activateMenu = () => {
    document.querySelector('.nav-item-wrapper').classList.toggle('active')
    document.querySelector('.nav-wrapper').classList.toggle('active')
    document.querySelector('.line1').classList.toggle('active')
    document.querySelector('.line2').classList.toggle('active')
    document.querySelector('.line3').classList.toggle('active')
    document.querySelector('.nav-item-wrapper').style.transition = 'ease-out .5s;'
}

export const Navigation = ({ galleryElement, ebookElement, contactElement }) => {

    const scrollPage = (elementRef) => {

        if (document.querySelector('.nav-item-wrapper.active')) 
            { activateMenu() }
        if (typeof elementRef === 'number') {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        else {
        window.scrollTo({ top: elementRef.current.offsetTop, behavior: 'smooth' })
        }
    }

    return (
        <div className='nav-wrapper'>
            <img onClick={() => { scrollPage(0) }} className='nav-logo-img' src='./logos/zag-logo-white.png' alt='' />
            <div className='nav-item-wrapper'>
                <div onClick={() => { scrollPage(0)}}>Home</div>
                <div onClick={() => { scrollPage(galleryElement) }}>Gallery</div>
                <div onClick={() => { scrollPage(ebookElement) }}>Ebook</div>
                <div onClick={() => { scrollPage(contactElement) }}>Contact</div>
            </div>
            <div className='hamburger-wrapper'>
                <div className='hamburger-menu' onClick={activateMenu}>
                    <span className='line1'></span>
                    <span className='line2'></span>
                    <span className='line3'></span>
                </div>
            </div>
        </div>
    )
}

export default Navigation;