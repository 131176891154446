import React, { useState } from 'react'
import {carouselPictures} from '../pictures/img_import.js'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export const PictureCarousel = () => {

    const [Num, setNum] = useState(0);

    const switchPictures = (direction) => {
        if (direction === 'back'){
        Num <= 0 ? setNum(carouselPictures.length - 1) : setNum(Num - 1)
        }
        if (direction === 'next') {
        Num === carouselPictures.length -1  ? setNum(0) : setNum(Num + 1)
        }
    }

    return (
        <div className='top-carousel-wrapper'>
               <div className='arrow-sub-wrapper'>
                <FaAngleLeft className='arrow' onClick={() => {switchPictures('back')}}/>
                </div> 
            <div className='carousel-image-wrapper'>
            <img src={carouselPictures[Num].src} alt={carouselPictures[Num].src} className='image'/>)
            </div>
            <div className='arrow-sub-wrapper'>
            <FaAngleRight className='arrow' onClick={() => {switchPictures('next')}}/>
            </div>
        
        </div>
    )
}

export default PictureCarousel
