import './App.css';
import Welcome from './components/Welcome';
import Gallery from './components/Gallery';
import Navigation from './components/Navigation'
import PictureCarousel from './components/PictureCarousel';
import EBook from './components/eBook';
import Contact from './components/Contact';
import {useState} from 'react'
// import BlogContainer from './components/blog/BlogContainer';


function App() {

const [galleryElement, setGalleryElement] = useState()
const [ebookElement, setEbookElement] = useState()
const [contactElement, setContactElement] = useState()

  return (
    <div className="App">
      <Navigation galleryElement = {galleryElement} ebookElement = {ebookElement} contactElement = {contactElement}/>
      <PictureCarousel />
      <Welcome />
      <Gallery setGalleryElement = {setGalleryElement} galleryElement = {galleryElement}/>
      <EBook setEbookElement={setEbookElement}/>
      <Contact setContactElement={setContactElement}/>
      {/* <BlogContainer/> */}
    </div>
  );
}

export default App;
