import React, { useRef, useEffect } from 'react'

export const EBook = ({ setEbookElement }) => {

    const ebookRef = useRef()

    useEffect(() => {
     setEbookElement(ebookRef);
    },[])

    return (
        <div className='ebook-wrapper' ref={ebookRef}>
            <div className='ebook-logo-wrapper'>
                <a className= 'ebook-link-logo' href='https://gum.co/sjdGt'>
            <img src='./logos/title_page.png' className='ebook-logo' alt='logo'/>
            </a>
            </div>

            <div className='ebook-text'>
            <h1>Get Our New eBook!</h1>
            <p>You can get a copy of Incredible Places to Explore in the Columbia River Gorge <a className='ebook-link' href='https://gum.co/sjdGt'>HERE!</a></p>
            <p>Learn about amazing waterfalls and landscapes in one of the most unique regions in America! Our eBook features high quality photographs as well as information about the best places to go in the surrounding area.</p>
            </div>



        </div>
    )
}

export default EBook;
