import React, { useRef, useEffect } from 'react'

export const Contact = ({ setContactElement }) => {

const contactRef = useRef()

    useEffect(() => {
     setContactElement(contactRef);
    },[])

    return (
            <div className='contact-wrapper' ref={contactRef}>
                    <h1> Questions? Business Inquiries? </h1>
                    <h1> Contact us at <a className='contact-link' href='mailto:zachandjessy@gmail.com'>zachandjessy@gmail.com</a></h1>
            </div>


    )
}

export default Contact
