
const carouselPictures = [
    {src: './carousel-pictures/Crown_web.jpg', title: 'Crown'},
    {src: './carousel-pictures/First-Snow_web.jpg', title: 'First Snow'},
    {src: './carousel-pictures/Layover_web.jpg', title: 'Layover'},
    {src: './carousel-pictures/Night-in-the-High-Desert_web.jpg', title: 'Night in the High Desert'},
    {src: './carousel-pictures/Morning-Serenity_web.jpg', title: 'Morning Serenity'},
    {src: './carousel-pictures/Still-Mornings_web.jpg', title: 'Still Mornings'},
    {src: './carousel-pictures/Silhouette_web.jpg', title: 'Silhouette'},
]

const galleryPictures = [
    {src: './gallery-pictures/Bliss-on-the-Morning-Lake.jpg', title: 'Lake of the Lost', link: 'https://zgordy.darkroom.tech/products/763368'},
    {src: './gallery-pictures/Timeless-Twilight.jpg', title: 'Timeless Twilight', link: 'https://zgordy.darkroom.tech/products/870396'},
    {src: './gallery-pictures/Last-Light.jpg', title: 'Last Light', link: 'https://zgordy.darkroom.tech/products/763371'},
    {src: './gallery-pictures/Morning-Light-of-Spring.JPG', title: 'Morning Light of Spring', link: 'https://zgordy.darkroom.tech/products/844776'},
    {src: './gallery-pictures/Morning-Light-on-Mt.-Hood.jpg', title: 'Morning Light on Mt. Hood', link:'https://zgordy.darkroom.tech/products/763372'},
    {src: './gallery-pictures/Mysticality.jpg', title: 'Mysticality', link: 'https://zgordy.darkroom.tech/products/763358'},
    {src: './gallery-pictures/Peace-on-the-Winter-Lake.jpg', title: 'Peace on the Winter Lake', link: 'https://zgordy.darkroom.tech/products/763354'},
    {src: './gallery-pictures/Remnants-of-the-Tide.jpg', title: 'Remnants of the Tide', link: 'https://zgordy.darkroom.tech/products/844771'},
    {src: './gallery-pictures/River-of-the-Red-Mountain.jpg', title: 'River of the Red Mountain', link: 'https://zgordy.darkroom.tech/products/844772'},
    {src: './gallery-pictures/Stormlands.jpg', title: 'Stormlands', link: 'https://zgordy.darkroom.tech/products/763370'},
    {src: './gallery-pictures/Winter-Cloak.jpg', title: 'Winter Cloak', link: 'https://zgordy.darkroom.tech/products/763382'},
    {src: "./gallery-pictures/Winter's-Kiss.jpg", title: "Winter's Kiss", link: 'https://zgordy.darkroom.tech/products/763373'},
    {src: "./gallery-pictures/Infinity.jpg", title: "Infinity", link: 'https://zgordy.darkroom.tech/products/763379'},
    {src: "./gallery-pictures/Olympic-Glory.jpg", title: "Olympic Glory", link: 'https://zgordy.darkroom.tech/products/870392'},
    {src: "./gallery-pictures/Secrets-of-the-Columbia.jpg", title: "Secrets of the Columbia", link: 'https://zgordy.darkroom.tech/products/870393'},
]


export {carouselPictures, galleryPictures};