import React, { useEffect, useRef } from 'react'
import { galleryPictures } from '../pictures/img_import.js'

export const Gallery = ({setGalleryElement}) => {

  const galleryRef = useRef()



  useEffect(() => {
   setGalleryElement(galleryRef);
  },[])


  return (
    <div className='super-gallery-wrapper' ref={galleryRef}>
      <div className='gallery-wrapper'>
        {galleryPictures.map(image => <a className='img-wrapper' href={image.link}><img className='gallery-image' src={image.src} alt={image.src} /></a>)}
      </div>
    </div>
  )
}
export default Gallery;
