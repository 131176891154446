// import React, {useState} from 'react'
// import {db} from '../firebase'

export const Welcome = () => {

    // const [email, setEmail] = useState('')

    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     await db.collection('emails').add({
    //         address:email
    //     })
    //     .then(() => alert('Thanks for signing up for our email list!'))
    //     .catch(error => {
    //         alert(error.message)
    //     })
    //     .then(()=>{setEmail('')})
    // }

    return (
        <div className='welcome-widget-wrapper'>
            <div className='welcome-columns'>
                <div className='welcome-logo-wrapper'>
                    <img className='welcome-logo' src='./logos/zag-logo.png' alt='logo'/>
                </div>
                <div className='welcome-text'>
                    <h1> Welcome To Our Gallery!</h1>
                     <p>We are two Washington based landscape photographers who have a passion for art and sharing the beauty of the world.</p>
                     <p>Be sure to check out our new Incredible Places to Explore eBook and buy a piece of artwork from our gallery. Simply select an image to view printing options and pricing.</p>
                     
                     {/* <div className= 'email-input-wrapper'>
                    <input className='email-input' type='text' value={email} onChange={(e)=> setEmail(e.target.value)} />
                    <div className='submit-email' onClick={handleSubmit}>Sign Up</div>
                    </div> */}
             
                </div>
            </div>
            
        </div>
    )
}

export default Welcome;
